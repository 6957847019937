import { Injectable } from "@angular/core";
import * as firebase from 'firebase/app';
import 'firebase/functions';
import { Observable, Subject } from "rxjs";
import { ComicService } from "./comic.service";
import { Date } from "../adapters/daily-creator-data";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private dataSubject = new Subject<Record<string, boolean>>();
  private isTVAdminOrAnalyst = new Subject<boolean>();

  seriesByAdminPhoneNumbers: {};
  constructor(
    private comicService: ComicService,
    private localStorageService: LocalStorageService
  ) { }

  emitLoadPreviousMonthData(data: Record<string, boolean>): void {
    this.dataSubject.next(data);
  }

  loadPreviousMonthData(): Subject<Record<string, boolean>> {
    return this.dataSubject;
  }

  emitIsTVAdminOrAnalyst(data: boolean): void {
    this.localStorageService.setItem('isTVAdminOrAnalyst', data);
    this.isTVAdminOrAnalyst.next(data);
  }

  loadIsTVAdminOrAnalyst(): Subject<boolean> {
    return this.isTVAdminOrAnalyst;
  }

  public getDirectoryData(): Observable<{ seriesToShow: { title: string; value: string }[]; }> {
    return new Observable((observer) => {
      const user = firebase.auth().currentUser;
      if (!user) {
        observer.error("User not authenticated");
        observer.complete();
        return;
      }
      if (this.seriesByAdminPhoneNumbers) {
        const matchedSeries = this.seriesByAdminPhoneNumbers[user.phoneNumber];
        observer.next({ seriesToShow: matchedSeries });
        observer.complete();
      } else {
        this.comicService.getComicTitle().subscribe(
          (res) => {
            this.seriesByAdminPhoneNumbers = this.getAdminsByPhoneNumber(res);
            const matchedSeries = this.seriesByAdminPhoneNumbers[user.phoneNumber];
            this.checkForTVAdminOrAnalyst(matchedSeries);
            observer.next({ seriesToShow: matchedSeries });
            observer.complete();
          },
          (error) => {
            // Handle errors, possibly log or return an error object
            console.error("Error fetching comic comic directory", error);
            observer.error("Error fetching comic directory");
            observer.complete();
          }
        );
      }
    });
  }

  checkForTVAdminOrAnalyst(series): void {
    const isTVAdminOrAnalyst = series && series.length && series.find(obj => obj.title === 'Tinyview');
    if (isTVAdminOrAnalyst) {
      this.emitIsTVAdminOrAnalyst(true);
    } else {
      this.emitIsTVAdminOrAnalyst(false);
    }
  }

  getAdminsByPhoneNumber(data): {} {
    const adminMap = {};
    const allSeries = [];
    Object.keys(data).forEach((seriesKey) => {
      const series = data[seriesKey];
      allSeries.push({ title: series.title, value: seriesKey })
      // Check if the series has roles
      if (series.roles && series.roles.length > 0 && seriesKey !== 'tinyview') {
        // Iterate through each role
        series.roles.forEach((role) => {
          // Check if the role has admins
          if (role.admins && role.admins.length > 0) {
            // Iterate through each admin phone number
            role.admins.forEach((adminPhoneNumber) => {
              // Create an entry in the adminMap
              if (!adminMap[adminPhoneNumber]) {
                adminMap[adminPhoneNumber] = [];
              }

              // Add the series key to the admin's array
              adminMap[adminPhoneNumber].push({ title: series.title, value: seriesKey });
              // title also..
              // adminSeries (without roles obj)
            });
          }
          if (role.analysts && role.analysts.length > 0) {
            // Iterate through each analysts phone number
            role.analysts.forEach((analystsPhoneNumber) => {
              // Create an entry in the adminMap
              if (!adminMap[analystsPhoneNumber]) {
                adminMap[analystsPhoneNumber] = [];
              }
              // Add the series key to the admin's array
              adminMap[analystsPhoneNumber].push({ title: series.title, value: seriesKey });
            });
          }
        });
      }
    });
    // Get tinyview admin & analysts number and assign them allSeries.
    if (data.tinyview.roles && data.tinyview.roles.length > 0) {
      // Iterate through each role
      data.tinyview.roles.forEach((role) => {
        // Check if the role has admins
        if (role.admins && role.admins.length > 0) {
          // Iterate through each admin phone number
          role.admins.forEach((adminPhoneNumber) => {
            // Create an entry in the adminMap
            adminMap[adminPhoneNumber] = this.sortSeriesAlphabatically(allSeries);
          });
        }
        if (role.analysts && role.analysts.length > 0) {
          // Iterate through each analysts phone number
          role.analysts.forEach((analystsPhoneNumber) => {
            // Create an entry in the adminMap
            adminMap[analystsPhoneNumber] = this.sortSeriesAlphabatically(allSeries);
          });
        }
      });
    }
    return adminMap;
  }

  sortSeriesAlphabatically(allSeries) {
    allSeries.sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
    return allSeries;
  }

  async getCreatorDashboardData(data): Promise<any> {
    const creatorDashboardData = firebase.functions().httpsCallable('getCreatorDashboardData');
    return creatorDashboardData({
      startDate: data.startDate,
      series: data.series
    }).then(res => res);
  }

  async getReferrerDashboardData(data): Promise<any> {
    const referrerDashboardData = firebase.functions().httpsCallable('getReferrerDashboardData');
    return referrerDashboardData({
      startDate: data.startDate,
      type: data.type || ''
    }).then(res => res);
  }

  async getDashboardData(data?): Promise<any> {
    const dashboardData = firebase.functions().httpsCallable('getDashboardData');
    const res = await dashboardData({
      dashboardType: data && data.dashboardType,
    });
    return res;
  }

  public getMessageForDate(timestamp: number, isTotals?: boolean): Date {
    const date = new Date(timestamp);
    const currentDate = new Date();
    let calculatedMessage;
    if (
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    ) {
      const currentDay = date.getDate(); // we need to show till last record, i.e. timestamp
      const dateString = currentDay === 1 ? '1' : `1-${currentDay}`;

      if (isTotals) {
        calculatedMessage = `As of ${this.getMonthName(date.getMonth())} ${currentDay}, ${currentDate.getFullYear()}.`;
      } else {
        calculatedMessage = `Calculated for ${this.getMonthName(date.getMonth())} ${dateString}, ${currentDate.getFullYear()}. Not final.`;
      }
    } else {
      calculatedMessage = `Calculated for ${this.getMonthName(date.getMonth())} ${date.getFullYear()}. Final.`;
    }
    return { displayValue: calculatedMessage, timestamp: timestamp };
  }

  // Helper function to get the month name by index
  public getMonthName(monthIndex) {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return months[monthIndex];
  }

  public sortArrayByDate(data) {
    // Custom sorting function
    const sortByTimestamp = (a, b) => {
      const timestampA = parseInt(a.date.timestamp);
      const timestampB = parseInt(b.date.timestamp);
      return timestampB - timestampA; // Sort in descending order, change the order for ascending
    };

    // Apply sorting
    const sortedData = data.sort(sortByTimestamp);

    return sortedData;
  }
}