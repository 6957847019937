import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { from, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoryFeedService {
  private comicAction = new Subject<string>();

  constructor() { }

  emitComicAction(data: string): void {
    this.comicAction.next(data);
  }

  loadComicAction(): Subject<string> {
    return this.comicAction;
  }

  getUserFeed(value: string): Observable<any> {
    const userFeed = firebase.functions().httpsCallable('getUserFeed');
    return from(userFeed({
      "startAfter": value,
      "records": 10
    }))
  }

  async getStory(action: string, image?: string, storyMeta?) {
    const getStory = firebase.functions().httpsCallable('getStory');
    const reqData = {
      "action": action
    }
    if (image) reqData['image'] = image;
    if (storyMeta) {
      reqData['url'] = storyMeta.url;
      reqData['title'] = storyMeta.title;
      reqData['description'] = storyMeta.description;
      reqData['series'] = storyMeta.series;
    }
    return getStory(reqData);
  }

  async getSeriesPageFeeds(value: any, series: any, count, onlyUnread = false) {
    const seriesPageFeeds = firebase.functions().httpsCallable('getComics');
    return seriesPageFeeds({
      "series": series.replace('/', ''),
      "startAfter": value,
      "records": count,
      "onlyUnread": onlyUnread
    })
  }

  async doLikeStory(id: any) {
    const getLikes = firebase.functions().httpsCallable('likeStory');
    return getLikes({
      "storyID": id,
      "reactionType": "LIKE"
    })
  }

  async doDislikeStory(id: any) {
    const getDisLikes = firebase.functions().httpsCallable('dislikeStory');
    return getDisLikes({
      "storyID": id,
      "reactionType": "LIKE"
    })
  }

  async getAllStoryLikes(ids: any) {
    const getAllLikes = firebase.functions().httpsCallable('getStoryLikes');
    return getAllLikes({
      "storyIDs": ids
    })
  }


  getAllComics(ids: any): Observable<any> {
    const getAllLikes = firebase.functions().httpsCallable('getAllComics');
    return from(getAllLikes({
      "startAfter": ids,
      "records": "20",
      "likeInfo": 1
    }))
  }

  public editStory(editedPanels: any) {
    const editStory = firebase.functions().httpsCallable('editStory');
    return editStory(editedPanels)
  }

  public deleteStory(deletedPanels: any) {
    const deleteStory = firebase.functions().httpsCallable('deleteStory');
    return deleteStory(deletedPanels)
  }

  public getFreinds() {
    const getFreinds = firebase.functions().httpsCallable('getUserFriends');
    return getFreinds()
  }

  public getComments(storyId: string, records: number, commentId: string, order = 'asc') {
    const getComment = firebase.functions().httpsCallable('fetchComments');
    return getComment({
      "storyID": storyId,
      "records": records,
      "startAfter": commentId,
      "sortingOrder": order
    });
  }

  async doLikeComment(storyID: string, commentID: string, subCommentID: string) {
    const likeComment = firebase.functions().httpsCallable('likeComment');
    return likeComment({
      "storyID": storyID,
      "commentID": commentID,
      "subCommentID": subCommentID
    })
  }

  async userLikedComments(commentID: string, subCommentID: string) {
    const fetchCommentLikes = firebase.functions().httpsCallable('fetchCommentLikes');
    return fetchCommentLikes({
      "commentID": commentID,
      "subCommentID": subCommentID,
      "records": 100,
      "startAfter": ""
    })
  }

  async doDislikeComment(storyID: string, commentID: string, subCommentID: string) {
    const disLikeComment = firebase.functions().httpsCallable('disLikeComment');
    return disLikeComment({
      "storyID": storyID,
      "commentID": commentID,
      "subCommentID": subCommentID
    })
  }

  async addComments(storyID: string, commentText: string, commentID: string) {
    const addComments = firebase.functions().httpsCallable('addComment');
    return addComments({
      "storyID": storyID,
      "commentText": commentText,
      "commentID": commentID
    })
  }

  async deleteComment(storyID: string, commentID: string, subCommentID: string) {
    const deleteComment = firebase.functions().httpsCallable('deleteComment');
    return deleteComment({
      "storyID": storyID,
      "commentID": commentID,
      "subCommentID": subCommentID
    })
  }

  async editComment(storyID: string, commentID: string, subCommentID: string, commentText: string) {
    const editComment = firebase.functions().httpsCallable('editComment');
    return editComment({
      "commentID": commentID,
      "subCommentID": subCommentID,
      "storyID": storyID,
      "commentText": commentText
    })
  }

  async getStoryByID(storyID: string) {
    const getStoryByID = firebase.functions().httpsCallable('getStory');
    return getStoryByID({
      "storyID": storyID
    })
  }

  async getStoryLikes(storyID: string, startAfterID: string, reactionType: string) {
    const getStoryLikes = firebase.functions().httpsCallable('fetchStoryLikes');
    return getStoryLikes({
      "storyID": storyID,
      "records": 100,
      "startAfter": startAfterID,
      "reactionType": reactionType
    })
  }
}
