import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { User } from '../models/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from './local-storage.service';
import { FirebaseService } from './firebase.service';
import { AUTH_ERROR_MESSAGES } from '../constants/error-message.constants';
import { FBQ_TRACKING_EVENTS, PLATFORM_TYPE, USER_TYPE } from '../constants/common.constants';
import { environment } from '../../environments/environment';
import { AUTH_TYPE, LOGIN_FLOW } from '../constants/common.constants';
import { UserService } from './user.service';
import { isAnonymousUser } from '../utilities/common.util';
import { FbqService } from './fbq.service';
import { NewComicService } from '../new-comic.service';
import { CacheService } from './cache.service';

interface PlatformHandlingData {
  isSamePlatform: boolean;
  isUserExistWithEmail: boolean;
  mergeFlow: boolean;
  emailLinkSourceData: any;
  emailID: string;
  emailLink: string;
};

const EMAIL_LINK_DATA = environment.EMAIL_LINK_DATA;
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  user$: Observable<User>;
  userData: any;
  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    private localStorageService: LocalStorageService,
    public ngZone: NgZone,
    @Inject(PLATFORM_ID) private platform: object,
    private readonly firebaseService: FirebaseService,
    private readonly userService: UserService,
    private fbqService: FbqService,
    private readonly newComicService: NewComicService,
    private cacheService: CacheService
  ) {
  }

  async signOut(isNavigate = true) {
    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await this.afAuth.auth.signOut();
      await this.afAuth.auth.signInAnonymously();

      if (isPlatformBrowser(this.platform)) {
        this.cacheService.remove('userDetails');
        this.localStorageService.removeItem('isAnalysts');
        this.localStorageService.removeItem('isTinyviewAnalyst');
        this.localStorageService.removeItem('tinyviewAdmin');
        this.localStorageService.removeItem('isSeriesCreator');
        this.localStorageService.removeItem('isAdmin');
        this.localStorageService.removeItem('isdashboardOpen');
        this.localStorageService.removeItem('isEmpty');
        this.localStorageService.removeItem('productID');
        this.localStorageService.setItem('isAnonymousUser', true);
        this.localStorageService.removeItem('hasSubscription');
        if (isNavigate) {
          // this.router.navigate(['/']);
          // if (location.pathname === '/') {
          //   location.reload();
          // }
          window.location.href = window.location.origin;
        }
      }
    } catch (error) {
      window.alert(error.message);
    }
  }

  public async signInPhoneNumber(phoneNum, appVerifier) {
    return await this.afAuth.auth.signInWithPhoneNumber(phoneNum, appVerifier);
  }

  public removeProfileImage() {
    const removeProfile = this.firebaseService.getFirebaseFunction('removeProfileImage');
    return removeProfile({});
  }
  // TODO: Depricate 'userProfile'
  public userProfile(data) {
    const updateProfile = this.firebaseService.getFirebaseFunction('updateUserProfile');
    return updateProfile(data[0]);
  }

  public getToken() {
    return firebase.auth().currentUser.getIdToken();
  }

  public async getCurrentUserToken() {
    return await firebase.auth().currentUser.getIdToken();
  }

  public async mergeAuthenticatedUser(data: { userToken?: string; userID?: string; anonymousID?: string; forceMerge?: boolean; }) {
    try {
      const reqData = {};
      data.userToken && (reqData['anonymousIdToken'] = data.userToken);
      data.userID && (reqData['userID'] = data.userID);
      data.anonymousID && (reqData['anonymousID'] = data.anonymousID);
      data.forceMerge && (reqData['forceMerge'] = data.forceMerge);

      const mergeUser = this.firebaseService.getFirebaseFunction('mergeAuthenticatedUser');
      return await mergeUser(reqData);
    } catch (error) {
      console.log('Error in mergeAuthenticatedUser: ', error.message);
    }
  }

  public uploadImage(name, image) {
    const uploadImage = this.firebaseService.getFirebaseFunction('uploadImage');
    return uploadImage({
      image: image,
      fileName: name
    })
  }

  public isAnonymousUser() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const isAnonymousUser: boolean = !this.isSignedInUser(user);
        this.localStorageService.setItem('isAnonymousUser', isAnonymousUser);
      }
    });
  }

  public isSignedInUser(user?) {
    const currentUser = user || firebase.auth().currentUser;
    const email = currentUser.email || '';
    const phone = currentUser.phoneNumber || '';
    const isSignedIn = currentUser && !currentUser.isAnonymous && (email !== '' || phone !== '');
    return isSignedIn;
  }

  // New functions starting from here
  public getEmailLinkData(flowDataID: string) {
    const emailLinkData = { ...EMAIL_LINK_DATA };
    emailLinkData.url += `?uuid=${flowDataID}`;
    // emailLinkData.url = `http://localhost:4200/verify-email/?uuid=${flowDataID}`; // For Development
    return emailLinkData;
  }

  public async sendEmailLink(email: string, currentFlow: string, redirectionType = '', series) {
    try {
      const data = await this.saveEmailAddress({ email, currentFlow, redirectionType, series });
      const flowDataID = data.data.data.ID;
      const emailLinkData = this.getEmailLinkData(flowDataID);

      return await this.firebaseService.sendEmailLink(email, emailLinkData);
    } catch (error) {
      throw new Error(error.message || error);
    }
  }

  public async saveEmailAddress(data: { email: string, currentFlow: string, redirectionType: string, series }) {
    try {
      if (!data.email) {
        throw new Error(AUTH_ERROR_MESSAGES.MISSING_EMAIL);
      }
      let targetUserID = '';
      if ((data.currentFlow === LOGIN_FLOW.ALERTS) && !isAnonymousUser()) {
        let profileData = await this.userService.getUserProfileByPhoneOrEmail(AUTH_TYPE.EMAIL, data.email);
        profileData = profileData.data && profileData.data.length && profileData.data[0];

        // If it is merge flow and if target user have both mobile and email, then do not proceed!
        if (profileData.email && profileData.phoneNumber) {
          throw new Error('A user with this email address already exists.');
        }

        // If it is merge flow and if target user have email, then we will do merge flow.
        if (profileData.email) {
          data.currentFlow = LOGIN_FLOW.MERGE.toLowerCase();
          targetUserID = profileData.id;
        }
      }
      const addEmail = this.firebaseService.getFirebaseFunction('addEmailFlowData');
      const currentUserTokenID = await this.getCurrentUserToken();
      const reqData = {
        email: data.email,
        platform: PLATFORM_TYPE.WEB,
        userType: USER_TYPE.ANONYMOUS,
        flow: data.currentFlow.toLowerCase(),
        anonymousIdToken: currentUserTokenID,
        metadata: { redirection_type: data.redirectionType } // "friend_request"
      };

      if (data.series.title !== '') reqData['series'] = data.series;
      // targetUserID: for merge flow purpose
      targetUserID && (reqData['targetUserID'] = targetUserID);

      return await addEmail(reqData);
    } catch (error) {
      throw new Error(error.message || error);
    }
  }

  public async checkForExistingUser(authType: string, authValue: string): Promise<boolean> {
    try {
      return this.userService.checkForExistingUser(authType, authValue);
    } catch (error) {
      throw new Error(error.message || error);
    }
  }

  public async signInWithEmail(email: string, emailLink: string) {
    try {
      return await this.firebaseService.signInWithEmail(email, emailLink);
    } catch (error) {
      throw new Error(error.message || error);
    }
  }

  public async getEmailFlowData(flowDataID: string) {
    try {
      if (!flowDataID) {
        throw new Error(AUTH_ERROR_MESSAGES.MISSING_FLOW_DATA_ID);
      }

      const emailFlowData = this.firebaseService.getFirebaseFunction('getEmailFlowData');
      const reqData = {
        flowDataID: flowDataID
      };
      return await emailFlowData(reqData);
    } catch (error) {
      throw new Error(error.message || error);
    }
  }

  public async emailAuthorization(emailUrl: string, userID: string): Promise<any> {
    let emailID: string;
    let currentFlow: string;
    try {
      const emailGenData = await this.getEmailFlowData(userID);
      const emailLinkSourceData = emailGenData.data.data;
      emailID = emailLinkSourceData.email;
      const emailLink = emailUrl;
      currentFlow = emailLinkSourceData.flow;
      const mergeFlow = emailLinkSourceData.flow === LOGIN_FLOW.MERGE.toLowerCase();
      const sourceUserTokenID = emailLinkSourceData.anonymousIdToken;
      const redirectionType = emailLinkSourceData.metadata.redirection_type;
      const series = emailLinkSourceData.series;

      // Check If ANY User exist with this email
      const isUserExistWithEmail = await this.checkForExistingUser(AUTH_TYPE.EMAIL, emailID);
      const isSamePlatform = this.userService.isSameUser(emailLinkSourceData.sourceUserID);

      const data = {
        isSamePlatform,
        isUserExistWithEmail,
        mergeFlow,
        emailLinkSourceData,
        emailID,
        emailLink
      };

      const isMergeUser = await this.handlePlatformActions(data);

      // updatUserProfile on SignUP || Alerts
      if ([LOGIN_FLOW.SIGNUP.toLowerCase(), LOGIN_FLOW.ALERTS.toLowerCase()].includes(currentFlow.toLowerCase())) {
        await this.userService.updateUserProfile(emailID);
      }

      if (isMergeUser && !mergeFlow) { // stopping mering in merge flow as we have already merged it earlier
        // MergerAuthenticated User with anonymous user (To restore any activity done by anonymous user before authenticating the link)
        await this.mergeAuthenticatedUser({ userToken: sourceUserTokenID });
      }

      return { currentFlow, redirectionType, series };

    } catch (error) {
      console.log('Error in emailAuthorization: ', error.message);
      throw { email: emailID, currentFlow: currentFlow };
    }
  }

  public async handlePlatformActions(data: PlatformHandlingData): Promise<boolean> {
    const {
      isSamePlatform,
      isUserExistWithEmail,
      mergeFlow,
      emailLinkSourceData,
      emailID,
      emailLink
    } = data;
    let isMergeUser = false;

    // Data For LinkWithCredential
    const credData = {
      authValue: emailID,
      authType: AUTH_TYPE.EMAIL,
      emailLink: emailLink
    };

    // FYI: isSamePlatform means When emailLink is open on same platform

    if (!isSamePlatform && ((isUserExistWithEmail && mergeFlow) || !isUserExistWithEmail)) {
      // When Link is open in different Platform
      const customToken = emailLinkSourceData.customToken;

      // SignIn with CustomToken (It will create user session of sourceUserID)
      await this.firebaseService.signInWithCustomToken(customToken);
    }

    if (isUserExistWithEmail && mergeFlow) {
      // If target user exist with email and if it is MERGE flow
      // Merge both users in BE (current user and target user)
      await this.mergeAuthenticatedUser({
        anonymousID: emailLinkSourceData.targetUserID,
        forceMerge: true
      });

      // Link the current user (logged in from phone) to email (MERGE)
      await this.firebaseService.linkWithCredential(credData);
    } else if (!isUserExistWithEmail) {
      // SIGNUP flow
      // Link the current user with this email (SIGNUP)
      await this.firebaseService.linkWithCredential(credData);
    } else {
      // SIGNIN flow
      // SignIn Source-User with this email.
      await this.signInWithEmail(emailID, emailLink);
      isMergeUser = true;
    }

    return isMergeUser;
  }

  public async loginWithPhoneOTP(otp: string, phoneNumber: string, confirmationResult: any) {
    let code: string = otp;
    // this.codefetchDone = false;

    if (!parseInt(code)) {
      return false;
    }
    // Fetching user profile using number
    const authType = AUTH_TYPE.PHONE;
    const userData = await this.userService.getUserProfileByPhoneOrEmail(authType, phoneNumber)

    try {
      if (userData && userData.data.length > 0) {
        // if user is present with this number [SIGNIN]
          const userToken = await this.getToken();
          await confirmationResult.confirm(code);
          if (userToken) {
            const res = await this.mergeAuthenticatedUser({ userToken });
          }
      } else {
        // SIGNUP
          const credData = {
            authType: AUTH_TYPE.PHONE,
            authValue: confirmationResult.verificationId,
            code
          }
          await this.firebaseService.linkWithCredential(credData);
          await this.userService.updateUserProfile('', phoneNumber);
          // to track new signups
          this.fbqService.trackEvent(FBQ_TRACKING_EVENTS.SIGNUP);
      }
      this.newComicService.sendRefreshLoginDataEvent();
      const val = await this.userService.getUserDetails();
      if (val.data.data.badges && val.data.data.badges.length) {
        const hasSubscription = true;
        this.localStorageService.setItem('hasSubscription', hasSubscription);
      }
    } catch (err) {
      console.log('code confirmationResult error', err);
      let verifyCodeErr = 'Something went wrong, Please try again!';
      if (err.code == 'auth/too-many-requests') {
        verifyCodeErr = 'Too many request from the same device  try again later.';
      } else if (err.code == 'auth/network-request-failed') {
        verifyCodeErr = 'Oops! Something is not right. Please check your internet connection.';
      } else if (err.code == 'auth/code-expired') {
        verifyCodeErr = 'The SMS code has expired. Please re-send the verification code to try again.';
      } else if (err.code == 'auth/invalid-verification-code') {
        verifyCodeErr = 'Invalid verification code.';
      } else if (err) {
        verifyCodeErr = err.message;
      }
      throw verifyCodeErr;
    }
  }

  public async signInPhone(phoneNumber, captchaVerifier) {
    try {
      // Signing in the user with phone number, this will also check if phone number is correct or not
      const res = await this.signInPhoneNumber(phoneNumber, captchaVerifier);
      // Phone number correct and code sent on it
      const confirmationResult = res;
      return confirmationResult
      // To show success toaster for resend otp
    } catch (error) {
      let phoneNumCodeErr = 'Something went wrong, Please try again!';
      if (error.code == 'auth/too-many-requests') {
        phoneNumCodeErr = 'Too many request from the same device. Please try again later.';
      } else if (error.code == 'internal') {
        phoneNumCodeErr = 'Oops! Something is not right. Please check your internet connection.';
      } else if (error.code == 'auth/captcha-check-failed') {
        phoneNumCodeErr = 'Oops! Something is not right. Please refresh the page and try again.';
      } else if (error.code == 'auth/invalid-phone-number') {
        phoneNumCodeErr = 'Invalid Phone Number.';
      } else if (error) {
        phoneNumCodeErr = error.message;
      }
      throw phoneNumCodeErr;
    }
  }
}
