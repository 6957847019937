import { Injectable } from "@angular/core";
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: "root",
})

export class FriendService {
  constructor() { }
  public async getFriendsByUserID(userID: string) {
    const getUserFriends = firebase.functions().httpsCallable('getUserFriends');
    const res = await getUserFriends({
        userID
    });
    return res.data;
  }

  public async removeFriendByFriendID(friendUID: string) {
    const removeFriend = firebase.functions().httpsCallable('removeFriend');
    const res = await removeFriend({
        friendUID
    });
    return res.data;
  }

  public async getFriendRequestsByType(input: any) {
    const getFriendRequests = firebase.functions().httpsCallable('getFriendRequests');
    const res = await getFriendRequests({
        requestType : input.requestType,
        phoneNumber : input.phoneNumber,
        status: input.status
    });
    return res.data;
  }

  public async updateFriendRequestByReqID(data) {
    const updateFriendRequest = firebase.functions().httpsCallable('updateFriendRequest');
    const res = await updateFriendRequest({
        ...data
    });
    return res.data;
  }
}