export const subscribeURL = '/tinyview/subscribe';
export const influencePointsURL = '/tinyview/influence-points';
export const signInPhoneURL = '/signin/phone/number';
export const signUpPhoneURL = '/signup/phone/number';
export const phoneVerificationURL = '/verification/phone/number';
export const phoneOTPVerificationURL = '/verification/phone/otp';
export const emailVerificationURL = '/verification/email';
export const emailSentForVerificationURL = '/verification/email-sent';
export const allSeriesURL = '/tinyview/comic-series-directory';

export const signUpURL = '/signup/dob';
export const absoluteSignUpURL = '/signup';
export const upgradeTitle = 'Upgrade';
export const latestComics = 'Latest Comics';
export const TINYVIEW = 'tinyview';
export const templateTypes = {
  bonus: 'bonus',
  directory: 'directory',
  episodes: 'episodes',
  newsfeed: 'newsfeed',
  story: 'story',
  stories: 'stories',
  toc: 'toc',
  carousel: 'carousel',
  list: 'list',
  premium: 'premium',
  comicLimit: 'comicLimit',
  month: 'month',
  card: 'card'
};

export const carouselType = {
  episodes: 'episodes',
  home: 'home',
  following: 'following',
  list: 'list',
  continueReading: 'continueReading',
  otherSeries: 'otherSeries'
}
export const BROWSER = { DESKTOP: 'desktopBrowser', ANDROID: 'androidBrowser', IOS: 'iosBrowser', WINDOWS: 'windowsBrowser' };

export const traceStates = {
  STATE_UNSPECIFIED: 0,
  INITIALIZED: 1,
  STARTED: 2,
  COMPLETED: 3
};

export const youtubeDomains = [
  'googlevideo.com',
  'gvt1.com',
  'video.google.com',
  'video.l.google.com',
  'youtu.be',
  'youtube.com',
  'yt3.ggpht.com',
  'yt.be',
  'ytimg.com',
  'ytimg.l.google.com',
  'ytkids.app.goo.gl',
  'yt-video-upload.l.google.com',
  'vimeo.com'
];

export const stylesJson = {
  "styles": {
    "desktop": {
      "body": {
        "font-family": "system-ui, sans-serif"
      },
      "h1": {
        "font-weight": "700",
        "font-size": "22px",
        "line-height": "120%",
        "color": "#3D3D3D"
      },
      "h2": {
        "font-weight": "700",
        "font-size": "18px",
        "line-height": "120%",
        "color": "#3D3D3D"
      },
      "p": {
        "font-weight": "400",
        "font-size": "16px",
        "line-height": "140%",
        "color": "#626262"
      },
      ".p-bold": {
        "font-weight": "700",
        "font-size": "16px",
        "line-height": "140%",
        "color": "#3D3D3D"
      },
      ".body-mini": {
        "font-weight": "400",
        "font-size": "14px",
        "line-height": "140%",
        "color": "#626262"
      },
      ".body-mini-bold": {
        "font-weight": "700",
        "font-size": "14px",
        "line-height": "140%",
        "color": "#3D3D3D"
      },
      ".comments": {
        "font-family": "Georgia, serif",
        "font-style": "italic",
        "font-weight": "400",
        "font-size": "16px",
        "line-height": "140%",
        "color": "#626262"
      }
    },
    "mobileWeb": {
      "body": {
        "font-family": "system-ui, sans-serif"
      },
      "h1": {
        "font-weight": "800",
        "font-size": "22px",
        "line-height": "140%",
        "color": "#3D3D3D"
      },
      "h2": {
        "font-weight": "800",
        "font-size": "16px",
        "line-height": "140%",
        "color": "#3D3D3D"
      },
      "p": {
        "font-weight": "400",
        "font-size": "15px",
        "line-height": "120%",
        "color": "#626262"
      },
      ".p-bold": {
        "font-weight": "800",
        "font-size": "15px",
        "line-height": "120%",
        "color": "#3D3D3D"
      },
      ".body-mini": {
        "font-weight": "400",
        "font-size": "13px",
        "line-height": "120%",
        "color": "#626262"
      },
      ".body-mini-bold": {
        "font-weight": "800",
        "font-size": "13px",
        "line-height": "120%",
        "color": "#3D3D3D"
      },
      ".comments": {
        "font-family": "Georgia, serif",
        "font-style": "italic",
        "font-weight": "400",
        "font-size": "15px",
        "line-height": "120%",
        "color": "#626262"
      }
    },
    "app": {
      "h1": {
        "fontFamily": "System",
        "fontSize": 24,
        "lineHeight": 34,
        "color": "#3D3D3D",
        "fontWeight": "800",
        "fontStyle": "normal"
      },
      "h2": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "800",
        "fontSize": 18,
        "lineHeight": 25,
        "color": "#3D3D3D"
      },
      "p": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "400",
        "fontSize": 16,
        "lineHeight": 19,
        "color": "#626262"
      },
      "pBold": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "800",
        "fontSize": 16,
        "lineHeight": 19,
        "color": "#3D3D3D"
      },
      "comments": {
        "fontFamily": "Georgia-italic",
        "fontStyle": "italic",
        "fontWeight": "400",
        "fontSize": 17,
        "lineHeight": 21,
        "color": "#626262"
      },
      "bodyMini": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "400",
        "fontSize": 14,
        "lineHeight": 17,
        "color": "#626262"
      },
      "bodyMiniBold": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "800",
        "fontSize": 14,
        "lineHeight": 17,
        "color": "#3D3D3D"
      }
    },
    "colors": {
      "logoRed": "#DF474B",
      "textBold": "#3D3D3D",
      "text": "#626262",
      "textInverse": "#FFFFFF",
      "separators": "#C4C4C4",
      "chatBubbles": "#E8E8E8",
      "borders": "#E8E8E8"
    }
  }
};

export const MONTHS_ARRAY = [
  { name: 'January', number: 1 },
  { name: 'February', number: 2 },
  { name: 'March', number: 3 },
  { name: 'April', number: 4 },
  { name: 'May', number: 5 },
  { name: 'June', number: 6 },
  { name: 'July', number: 7 },
  { name: 'August', number: 8 },
  { name: 'September', number: 9 },
  { name: 'October', number: 10 },
  { name: 'November', number: 11 },
  { name: 'December', number: 12 }
];

export const REDIRECTION_TYPES = [
  'request_accept',
  'story',
  'added_influence'
]

export const REFERRAL_CONSTS = {
  CREATOR: 'creator',
  SUBSCRIBER: 'subscriber',
  USER: 'user',
  REFERRAL: 'referral'
};

export const FBQ_TRACKING_EVENTS = {
  PURCHASE: 'Purchase',
  SUBSCRIPITON: 'Subscribe',
  SIGNUP: 'CompleteRegistration'
};

export const CREATOR_COL2_SUBHEAD = {
  subCol1: {
    value: 'Total',
    class: 'series-view-total'
  },
  subCol2: {
    value: 'Web',
    class: 'devices'
  },
  subCol3: {
    value: 'iOS',
    class: 'devices'
  },
  subCol4: {
    value: 'Android',
    class: 'devices'
  }
}

export const CREATOR_DASHBOARD_COLUMNS = {
  class: 'creator-row',
  col1: {
    value: 'Date',
    class: 'date-col'
  },
  col2: {
    value: 'Series Pageviews',
    class: 'series-views',
    subHead: CREATOR_COL2_SUBHEAD
  },
  col3: {
    value: 'Series %',
    class: 'series-col',
    tooltip: 'Series Pageviews as a percentage of Tinyview Pageviews.'
  },
  col4: {
    value: 'Pageviews Revenue',
    class: 'page-revenue',
    tooltip: 'Series Pageviews as a percentage of Tinyview Pageviews.'
  },
  col5: {
    value: 'Virtual Gifts',
    class: 'gift-col'
  },
  col6: {
    value: 'Series Revenue',
    class: 'series-revenue'
  }
}

export const REFERRAL_COL_2_SUBHEAD = {
  subCol1: {
    value: 'Referrals',
    class: 'referral'
  },
  subCol2: {
    value: 'Revenue',
    class: 'referral'
  },
}

export const REFERRAL_DASHBOARD_COLUMNS = {
  class: 'referral-row',
  col1: {
    value: 'Date',
    class: 'date-col'
  },
  col2: {
    value: 'Referral Revenue',
    class: 'referral-revenue',
    tooltip: 'Revenue generated by referrals',
    subHead: REFERRAL_COL_2_SUBHEAD
  },
}

export const TOP_REFERRER_DASHBOARD_COLUMNS = {
  class: 'top-referrer-row',
  col1: {
    value: 'Top Referrers',
    class: 'date-col'
  },
  col3: {
    value: 'Referrals',
    class: 'date-col',
  },
  col4: {
    value: 'Revenue',
    class: 'date-col',
  }
}

export const TOTALS_DASHBOARD_COLUMNS = {
  class: 'totals-row',
  col1: {
    value: '',
    class: 'totals-col totals-col-1'
  },
  col3: {
    value: '',
    class: 'totals-col',
  },
}

export const AUTH_TYPE = {
  EMAIL: 'email',
  PHONE: 'phone'
};

export const LOGIN_FLOW = {
  SIGNUP: 'SIGNUP',
  SIGNIN: 'SIGNIN',
  ALERTS: 'ALERTS',
  MERGE: 'MERGE',
  MERGED: 'MERGED',
};

export const PLATFORM_TYPE = {
  WEB: 'web',
  APP: 'app'
};

export const USER_TYPE = {
  ANONYMOUS: 'anonymous',
  ACTIVE: 'active'
};

export const MANAGE_ALERTS_MAPPING = {
  push: 'Push Notifications',
  email: 'Email',
  sms: 'SMS Text',
  publishComic: 'New content from series you follow',
  likeStory: 'A friend likes a post',
  likeComment: 'Some user likes your comment',
  commentOnStory: 'A friend comments on a post',
  commentOnStoryCreator: '[Creators] A user comments on your post',
  giftCreator: '[Creators] A user gifts you',
  giftUser: 'A friend gifts a creator',
  friendActivity: 'Friend network',
  flagComment: '[Creators] A user reports a comment',
  influencePoints: 'Influence points activity'
};

export const ACTIONS = [
  'manageAlerts'
];

export const ALLOWED_ACTION_TYPES = [
  'website'
];